@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '../node_modules/font-awesome/css/font-awesome.min.css';
html, body {
  font-family: "Open Sans" !important;
  overflow: hidden;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

.customBackgroundClass {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0,0,0,0.5);*/
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  opacity: 0.6;
  pointer-events: none;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  -webkit-animation: 0.2s ease-in borderscale;
  animation: 0.2s ease-in borderscale;
  background: #00a3e5 !important;
  border: 1px solid #00a3e5 !important;
  width: 13px !important;
  height: 13px !important;
}

.emp-comment-scroll {
  height: calc(100vh - 265px);
  overflow-y: scroll;
}

.emp-addcomment-scroll {
  height: calc(100vh - 404px);
  overflow-y: scroll;
}

.icon-Plane_1_POS::before {
  font-family: "Deloitte_icon_font_v3";
  font-size: 22px;
  color: #a6aaab !important;
  content: "";
  position: relative;
  top: 1px;
}

.icon-Home_house_POS::before {
  font-family: "Deloitte_icon_font_v3";
  font-size: 22px;
  color: #a6aaab !important;
  content: "";
  position: relative;
  top: 1px;
}

.icon-Dice_POS::before {
  font-family: "Deloitte_icon_font_v3";
  font-size: 22px;
  color: #a6aaab !important;
  content: "";
  position: relative;
  top: 1px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0cdcd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d0cdcd;
}

/*Admin*/
.device-type-header {
  padding-left: 24px;
  padding-bottom: 15px;
}

.device-type-count {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.ngx-pagination {
  font-family: "Open Sans" !important;
  padding: 0 !important;
}

.add-device-type-text {
  padding-bottom: 15px;
}

label.device-text {
  font-size: 13px;
  font-weight: 600;
}

.devicetypebutton {
  text-align: right;
  margin-left: -10px;
}

table tr, td, th {
  font-family: "Open Sans" !important;
}

.back-to-delinquencies {
  margin: 16px 0px 0px -22px;
  padding: 0;
  z-index: 99999 !important;
}

.hidedata-text-back-text {
  font-size: 13px;
  color: #53565A;
  font-weight: 700;
  top: 1px;
  position: relative;
  cursor: pointer;
}

.compliance-text-header {
  font-size: 18px;
  margin-top: 13px;
  color: #000000;
  margin-left: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.compliance-text {
  font-size: 15px;
  color: #000000;
  margin-left: -3px;
  margin-bottom: 0;
  margin-top: 15px;
}

.admin-text-header {
  font-size: 18px;
  margin-top: 13px;
  color: #000000;
  margin-left: 15px;
  font-weight: bold;
  margin-bottom: 0;
}

.admin-text {
  font-size: 15px;
  color: #000000;
  margin-left: 15px;
}

.eligibility-text-header {
  font-size: 18px;
  margin-top: 13px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0;
}

.eligibility-text {
  font-size: 15px;
  color: #000000;
}

#btn_close {
  font-size: 24px;
}

#mainDiv {
  margin-top: 20px;
}

.required-field {
  color: red;
  font-size: 12px;
}

.mark-inactive {
  position: relative;
  font-size: 13px;
  color: #000000;
  top: 2px;
}

.edit-status {
  cursor: pointer;
  padding-bottom: 3px;
}

.dotred {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotgreen {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotgrey {
  height: 10px;
  width: 10px;
  background-color: lightgrey;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.fa-search {
  cursor: pointer;
  color: #00A3E0;
}

.search-filter-margin {
  margin-top: -13px;
}

.side-filter-nav {
  padding-left: 0px;
}

.pane-margin {
  padding-left: 0;
}

.copy-clipboard {
  padding-left: 5px;
  cursor: pointer;
}