@charset "UTF-8";
@font-face {
  font-family: "Deloitte_icon_font_v3";
  src: url("/assets/fonts/Deloitte_icon_font_v3.eot?12xmfx");
  src: url("fonts/Deloitte_icon_font_v3.eot?12xmfx#iefix") format("embedded-opentype"), url("fonts/Deloitte_icon_font_v3.ttf?12xmfx") format("truetype"), url("fonts/Deloitte_icon_font_v3.woff?12xmfx") format("woff"), url("fonts/Deloitte_icon_font_v3.svg?12xmfx#Deloitte_icon_font_v3") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Deloitte_icon_font_v3" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-3D_NEG:before {
  content: "";
}

.icon-3D_POS:before {
  content: "";
}

.icon-Abacus_NEG:before {
  content: "";
}

.icon-Abacus_POS:before {
  content: "";
}

.icon-Alarm_clock_NEG:before {
  content: "";
}

.icon-Alarm_clock_POS:before {
  content: "";
}

.icon-Ambulance_NEG:before {
  content: "";
}

.icon-Ambulance_POS:before {
  content: "";
}

.icon-Anchor_NEG:before {
  content: "";
}

.icon-Anchor_POS:before {
  content: "";
}

.icon-Apple_NEG:before {
  content: "";
}

.icon-Apple_POS:before {
  content: "";
}

.icon-Archive_box_NEG:before {
  content: "";
}

.icon-Archive_box_POS:before {
  content: "";
}

.icon-Armchair_NEG:before {
  content: "";
}

.icon-Armchair_POS:before {
  content: "";
}

.icon-Arrow_double_down_NEG:before {
  content: "";
}

.icon-Arrow_double_down_POS:before {
  content: "";
}

.icon-Arrow_double_Left_NEG:before {
  content: "";
}

.icon-Arrow_double_Left_POS:before {
  content: "";
}

.icon-Arrow_double_right_NEG:before {
  content: "";
}

.icon-Arrow_double_right_POS:before {
  content: "";
}

.icon-Arrow_double_up_NEG:before {
  content: "";
}

.icon-Arrow_double_up_POS:before {
  content: "";
}

.icon-Arrow_down_Left_turn_NEG:before {
  content: "";
}

.icon-Arrow_down_Left_turn_POS:before {
  content: "";
}

.icon-Arrow_down_NEG:before {
  content: "";
}

.icon-Arrow_down_POS:before {
  content: "";
}

.icon-Arrow_down_right_turn_NEG:before {
  content: "";
}

.icon-Arrow_down_right_turn_POS:before {
  content: "";
}

.icon-Arrow_journey_NEG:before {
  content: "";
}

.icon-Arrow_journey_POS:before {
  content: "";
}

.icon-Arrow_Left_n_right_NEG:before {
  content: "";
}

.icon-Arrow_Left_n_right_POS:before {
  content: "";
}

.icon-Arrow_Left_down_turn_NEG:before {
  content: "";
}

.icon-Arrow_Left_down_turn_POS:before {
  content: "";
}

.icon-Arrow_Left_NEG:before {
  content: "";
}

.icon-Arrow_Left_POS:before {
  content: "";
}

.icon-Arrow_Left_up_turn_NEG:before {
  content: "";
}

.icon-Arrow_Left_up_turn_POS:before {
  content: "";
}

.icon-Arrow_right_down_turn_NEG:before {
  content: "";
}

.icon-Arrow_right_down_turn_POS:before {
  content: "";
}

.icon-Arrow_right_NEG:before {
  content: "";
}

.icon-Arrow_right_POS:before {
  content: "";
}

.icon-Arrow_right_up_turn_NEG:before {
  content: "";
}

.icon-Arrow_right_up_turn_POS:before {
  content: "";
}

.icon-Arrow_up_n_down_NEG:before {
  content: "";
}

.icon-Arrow_up_n_down_POS:before {
  content: "";
}

.icon-Arrow_up_Left_turn_NEG:before {
  content: "";
}

.icon-Arrow_up_Left_turn_POS:before {
  content: "";
}

.icon-Arrow_up_NEG:before {
  content: "";
}

.icon-Arrow_up_POS:before {
  content: "";
}

.icon-Arrow_up_right_turn_NEG:before {
  content: "";
}

.icon-Arrow_up_right_turn_POS:before {
  content: "";
}

.icon-Asterisk_NEG:before {
  content: "";
}

.icon-Asterisk_POS:before {
  content: "";
}

.icon-Audio_1_NEG:before {
  content: "";
}

.icon-Audio_1_POS:before {
  content: "";
}

.icon-Audio_2_NEG:before {
  content: "";
}

.icon-Audio_2_POS:before {
  content: "";
}

.icon-Axe_NEG:before {
  content: "";
}

.icon-Axe_POS:before {
  content: "";
}

.icon-Baby_bottle_NEG:before {
  content: "";
}

.icon-Baby_bottle_POS:before {
  content: "";
}

.icon-Baby_NEG:before {
  content: "";
}

.icon-Baby_POS:before {
  content: "";
}

.icon-Baby_stroller_NEG:before {
  content: "";
}

.icon-Baby_stroller_POS:before {
  content: "";
}

.icon-Backup_complete_NEG:before {
  content: "";
}

.icon-Backup_complete_POS:before {
  content: "";
}

.icon-Backup_NEG:before {
  content: "";
}

.icon-Backup_POS:before {
  content: "";
}

.icon-Band_aid_NEG:before {
  content: "";
}

.icon-Band_aid_POS:before {
  content: "";
}

.icon-Bar_chart_graph_1_NEG:before {
  content: "";
}

.icon-Bar_chart_graph_1_POS:before {
  content: "";
}

.icon-Bar_chart_graph_2_NEG:before {
  content: "";
}

.icon-Bar_chart_graph_2_POS:before {
  content: "";
}

.icon-basketball_NEG:before {
  content: "";
}

.icon-basketball_POS:before {
  content: "";
}

.icon-Battery_0_NEG:before {
  content: "";
}

.icon-Battery_0_POS:before {
  content: "";
}

.icon-Battery_1_NEG:before {
  content: "";
}

.icon-Battery_1_POS:before {
  content: "";
}

.icon-Battery_2_NEG:before {
  content: "";
}

.icon-Battery_2_POS:before {
  content: "";
}

.icon-Battery_3_NEG:before {
  content: "";
}

.icon-Battery_3_POS:before {
  content: "";
}

.icon-Battery_4_NEG:before {
  content: "";
}

.icon-Battery_4_POS:before {
  content: "";
}

.icon-Battery_5_NEG:before {
  content: "";
}

.icon-Battery_5_POS:before {
  content: "";
}

.icon-Battery_signs_NEG:before {
  content: "";
}

.icon-Battery_signs_POS:before {
  content: "";
}

.icon-Beaker_NEG:before {
  content: "";
}

.icon-Beaker_POS:before {
  content: "";
}

.icon-Bed_NEG:before {
  content: "";
}

.icon-Bed_POS:before {
  content: "";
}

.icon-Beer_glass_1_NEG:before {
  content: "";
}

.icon-Beer_glass_1_POS:before {
  content: "";
}

.icon-Beer_cup_2_NEG:before {
  content: "";
}

.icon-Beer_cup_2_POS:before {
  content: "";
}

.icon-Bell_alert_notification_NEG:before {
  content: "";
}

.icon-Bell_alert_notification_POS:before {
  content: "";
}

.icon-Bicycle_NEG:before {
  content: "";
}

.icon-Bicycle_POS:before {
  content: "";
}

.icon-Bin_trash_delete_NEG:before {
  content: "";
}

.icon-Bin_trash_delete_POS:before {
  content: "";
}

.icon-Binoculars_NEG:before {
  content: "";
}

.icon-Binoculars_POS:before {
  content: "";
}

.icon-Bird_NEG:before {
  content: "";
}

.icon-Bird_POS:before {
  content: "";
}

.icon-Birthday_cake_NEG:before {
  content: "";
}

.icon-Birthday_cake_POS:before {
  content: "";
}

.icon-Bitcoin_NEG:before {
  content: "";
}

.icon-Bitcoin_POS:before {
  content: "";
}

.icon-Blog_NEG:before {
  content: "";
}

.icon-Blog_POS:before {
  content: "";
}

.icon-Bluetooth_NEG:before {
  content: "";
}

.icon-Bluetooth_POS:before {
  content: "";
}

.icon-Bone_NEG:before {
  content: "";
}

.icon-Bone_POS:before {
  content: "";
}

.icon-Book_closed_NEG:before {
  content: "";
}

.icon-Book_closed_POS:before {
  content: "";
}

.icon-Book_open_NEG:before {
  content: "";
}

.icon-Book_open_POS:before {
  content: "";
}

.icon-Bookmark_2_NEG:before {
  content: "";
}

.icon-Bookmark_2_POS:before {
  content: "";
}

.icon-Bookmark_NEG:before {
  content: "";
}

.icon-Bookmark_POS:before {
  content: "";
}

.icon-Bow_tie_NEG:before {
  content: "";
}

.icon-Bow_tie_POS:before {
  content: "";
}

.icon-Brain_NEG:before {
  content: "";
}

.icon-Brain_POS:before {
  content: "";
}

.icon-Bridge_1_NEG:before {
  content: "";
}

.icon-Bridge_1_POS:before {
  content: "";
}

.icon-Bridge_2_NEG:before {
  content: "";
}

.icon-Bridge_2_POS:before {
  content: "";
}

.icon-Bridge_water_1_NEG:before {
  content: "";
}

.icon-Bridge_water_1_POS:before {
  content: "";
}

.icon-Bridge_water_2_NEG:before {
  content: "";
}

.icon-Bridge_water_2_POS:before {
  content: "";
}

.icon-Briefcase_bag_job_work_NEG:before {
  content: "";
}

.icon-Briefcase_POS:before {
  content: "";
}

.icon-Broadcast_NEG:before {
  content: "";
}

.icon-Broadcast_POS:before {
  content: "";
}

.icon-Broken_heart_NEG:before {
  content: "";
}

.icon-Broken_heart_POS:before {
  content: "";
}

.icon-Buffering_1_loader_spin_NEG:before {
  content: "";
}

.icon-Buffering_1_loader_spin_POS:before {
  content: "";
}

.icon-Buffering_2_loader_spin_NEG:before {
  content: "";
}

.icon-Buffering_2_loader_spin_POS:before {
  content: "";
}

.icon-Bug_NEG:before {
  content: "";
}

.icon-Bug_POS:before {
  content: "";
}

.icon-Bullseye_target_goal_NEG:before {
  content: "";
}

.icon-Bullseye_target_goal_POS:before {
  content: "";
}

.icon-Burger_Menu_NEG:before {
  content: "";
}

.icon-Burger_Menu_POS:before {
  content: "";
}

.icon-Cactus_NEG:before {
  content: "";
}

.icon-Cactus_POS:before {
  content: "";
}

.icon-CAD_3D_NEG:before {
  content: "";
}

.icon-CAD_3D_POS:before {
  content: "";
}

.icon-Calculator_NEG:before {
  content: "";
}

.icon-Calculator_POS:before {
  content: "";
}

.icon-Calendar_minus_NEG:before {
  content: "";
}

.icon-Calendar_minus_POS:before {
  content: "";
}

.icon-Calendar_multiply_NEG:before {
  content: "";
}

.icon-Calendar_multiply_POS:before {
  content: "";
}

.icon-Calendar_plus_NEG:before {
  content: "";
}

.icon-Calendar_plus_POS:before {
  content: "";
}

.icon-Calendar_tick_NEG:before {
  content: "";
}

.icon-Calendar_tick_POS:before {
  content: "";
}

.icon-Calender_NEG:before {
  content: "";
}

.icon-Calender_POS:before {
  content: "";
}

.icon-Camera_NEG:before {
  content: "";
}

.icon-Camera_POS:before {
  content: "";
}

.icon-Candle_NEG:before {
  content: "";
}

.icon-Candle_POS:before {
  content: "";
}

.icon-Capital_dome_NEG:before {
  content: "";
}

.icon-Capital_dome_POS:before {
  content: "";
}

.icon-Car_NEG:before {
  content: "";
}

.icon-Car_POS:before {
  content: "";
}

.icon-Carshare_NEG:before {
  content: "";
}

.icon-Carshare_POS:before {
  content: "";
}

.icon-Cat_NEG:before {
  content: "";
}

.icon-Cat_POS:before {
  content: "";
}

.icon-CCTV_NEG:before {
  content: "";
}

.icon-CCTV_POS:before {
  content: "";
}

.icon-CD_NEG:before {
  content: "";
}

.icon-CD_POS:before {
  content: "";
}

.icon-Champagne_bottle_NEG:before {
  content: "";
}

.icon-Champagne_bottle_POS:before {
  content: "";
}

.icon-Check_NEG:before {
  content: "";
}

.icon-Check_POS:before {
  content: "";
}

.icon-Checker_flag_NEG:before {
  content: "";
}

.icon-Checker_flag_POS:before {
  content: "";
}

.icon-Chess_bishop_NEG:before {
  content: "";
}

.icon-Chess_bishop_POS:before {
  content: "";
}

.icon-Chess_castle_NEG:before {
  content: "";
}

.icon-Chess_castle_POS:before {
  content: "";
}

.icon-Chess_king_NEG:before {
  content: "";
}

.icon-Chess_king_POS:before {
  content: "";
}

.icon-Chess_knight_NEG:before {
  content: "";
}

.icon-Chess_knight_POS:before {
  content: "";
}

.icon-Chess_pawn_NEG:before {
  content: "";
}

.icon-Chess_pawn_POS:before {
  content: "";
}

.icon-Chess_queen_NEG:before {
  content: "";
}

.icon-Chess_queen_POS:before {
  content: "";
}

.icon-Claperboard_video_movie_NEG:before {
  content: "";
}

.icon-Claperboard_video_movie_POS:before {
  content: "";
}

.icon-Clipboard_NEG:before {
  content: "";
}

.icon-Clipboard_POS:before {
  content: "";
}

.icon-Clock_NEG:before {
  content: "";
}

.icon-Clock_POS:before {
  content: "";
}

.icon-Cloud_NEG:before {
  content: "";
}

.icon-Cloud_POS:before {
  content: "";
}

.icon-Coat_hanger_NEG:before {
  content: "";
}

.icon-Coat_hanger_POS:before {
  content: "";
}

.icon-Cocktail_glass_NEG:before {
  content: "";
}

.icon-Cocktail_glass_POS:before {
  content: "";
}

.icon-Code_NEG:before {
  content: "";
}

.icon-Code_POS:before {
  content: "";
}

.icon-Coffee_cup_NEG:before {
  content: "";
}

.icon-Coffee_cup_POS:before {
  content: "";
}

.icon-Cog_settings_NEG:before {
  content: "";
}

.icon-Cog_settings_POS:before {
  content: "";
}

.icon-Cogs_settings_NEG:before {
  content: "";
}

.icon-Cogs_settings_POS:before {
  content: "";
}

.icon-Coin_stack_money_NEG:before {
  content: "";
}

.icon-Coin_stack_money_POS:before {
  content: "";
}

.icon-Commenting_chat_bubble_NEG:before {
  content: "";
}

.icon-Commenting_chat_bubble_POS:before {
  content: "";
}

.icon-Comparison_NEG:before {
  content: "";
}

.icon-Comparison_POS:before {
  content: "";
}

.icon-Compass_1_NEG:before {
  content: "";
}

.icon-Compass_1_POS:before {
  content: "";
}

.icon-Compass_2_NEG:before {
  content: "";
}

.icon-Compass_2_POS:before {
  content: "";
}

.icon-Cooling_towers_NEG:before {
  content: "";
}

.icon-Cooling_towers_POS:before {
  content: "";
}

.icon-Copy_file_document_NEG:before {
  content: "";
}

.icon-Copy_file_document_POS:before {
  content: "";
}

.icon-Crane_NEG:before {
  content: "";
}

.icon-Crane_POS:before {
  content: "";
}

.icon-Credit_card_NEG:before {
  content: "";
}

.icon-Credit_card_POS:before {
  content: "";
}

.icon-Crop_NEG:before {
  content: "";
}

.icon-Crop_POS:before {
  content: "";
}

.icon-Cross_close_NEG:before {
  content: "";
}

.icon-Cross_close_POS:before {
  content: "";
}

.icon-Currency_money_NEG:before {
  content: "";
}

.icon-Currency_money_POS:before {
  content: "";
}

.icon-Cursor_NEG:before {
  content: "";
}

.icon-Cursor_POS:before {
  content: "";
}

.icon-Cutlery_knife_fork_NEG:before {
  content: "";
}

.icon-Cutlery_knife_fork_POS:before {
  content: "";
}

.icon-Decrease_1_arrow_NEG:before {
  content: "";
}

.icon-Decrease_1_arrow_POS:before {
  content: "";
}

.icon-Decrease_2_arrow_NEG:before {
  content: "";
}

.icon-Decrease_2_arrow_POS:before {
  content: "";
}

.icon-Decrease_3_arrow_NEG:before {
  content: "";
}

.icon-Decrease_3_arrow_POS:before {
  content: "";
}

.icon-Decrease_4_arrow_NEG:before {
  content: "";
}

.icon-Decrease_4_arrow_POS:before {
  content: "";
}

.icon-Decrease_5_arrow_NEG:before {
  content: "";
}

.icon-Decrease_5_arrow_POS:before {
  content: "";
}

.icon-Devices_NEG:before {
  content: "";
}

.icon-Devices_POS:before {
  content: "";
}

.icon-Diamond_NEG:before {
  content: "";
}

.icon-Diamond_POS:before {
  content: "";
}

.icon-Dice_NEG:before {
  content: "";
}

.icon-Dice_POS:before {
  content: "";
}

.icon-Diploma_document_NEG:before {
  content: "";
}

.icon-Diploma_document_POS:before {
  content: "";
}

.icon-Direction_arrow_NEG:before {
  content: "";
}

.icon-Direction_arrow_POS:before {
  content: "";
}

.icon-Direction_sign_NEG:before {
  content: "";
}

.icon-Direction_sign_POS:before {
  content: "";
}

.icon-Directions_1_arrow_NEG:before {
  content: "";
}

.icon-Directions_1_arrow_POS:before {
  content: "";
}

.icon-Directions_2_arrow_NEG:before {
  content: "";
}

.icon-Directions_2_arrow_POS:before {
  content: "";
}

.icon-Dislike_NEG:before {
  content: "";
}

.icon-Dislike_POS:before {
  content: "";
}

.icon-Display_device_monitor_NEG:before {
  content: "";
}

.icon-Display_device_monitor_POS:before {
  content: "";
}

.icon-Divide_NEG:before {
  content: "";
}

.icon-Divide_POS:before {
  content: "";
}

.icon-DNA_NEG:before {
  content: "";
}

.icon-DNA_POS:before {
  content: "";
}

.icon-Do_not_walk_NEG:before {
  content: "";
}

.icon-Do_not_walk_POS:before {
  content: "";
}

.icon-Document_file_1_NEG:before {
  content: "";
}

.icon-Document_file_1_POS:before {
  content: "";
}

.icon-Document_file_2_NEG:before {
  content: "";
}

.icon-Document_file_2_POS:before {
  content: "";
}

.icon-Document_file_3_NEG:before {
  content: "";
}

.icon-Document_file_3_POS:before {
  content: "";
}

.icon-Dog_NEG:before {
  content: "";
}

.icon-Dog_POS:before {
  content: "";
}

.icon-Dollar_NEG:before {
  content: "";
}

.icon-Dollar_POS:before {
  content: "";
}

.icon-Dolly_NEG:before {
  content: "";
}

.icon-Dolly_POS:before {
  content: "";
}

.icon-Double_quote_marks_NEG:before {
  content: "";
}

.icon-Double_quote_marks_POS:before {
  content: "";
}

.icon-Download_from_cloud_NEG:before {
  content: "";
}

.icon-Download_from_cloud_POS:before {
  content: "";
}

.icon-Download_NEG:before {
  content: "";
}

.icon-Download_POS:before {
  content: "";
}

.icon-Drop_NEG:before {
  content: "";
}

.icon-Drop_POS:before {
  content: "";
}

.icon-Edit_profile_user_NEG:before {
  content: "";
}

.icon-Edit_profile_user_POS:before {
  content: "";
}

.icon-Egg_timer_hourglass_NEG:before {
  content: "";
}

.icon-Egg_timer_hourglass_POS:before {
  content: "";
}

.icon-Eject_NEG:before {
  content: "";
}

.icon-Eject_POS:before {
  content: "";
}

.icon-Elevator_up_down_NEG:before {
  content: "";
}

.icon-Elevator_up_down_POS:before {
  content: "";
}

.icon-Ellipsis_dots_NEG:before {
  content: "";
}

.icon-Ellipsis_dots_POS:before {
  content: "";
}

.icon-Enter_login_NEG:before {
  content: "";
}

.icon-Enter_POS:before {
  content: "";
}

.icon-Equals_NEG:before {
  content: "";
}

.icon-Equals_POS:before {
  content: "";
}

.icon-Eraser_NEG:before {
  content: "";
}

.icon-Eraser_POS:before {
  content: "";
}

.icon-Error_NEG:before {
  content: "";
}

.icon-Error_POS:before {
  content: "";
}

.icon-Euros_NEG:before {
  content: "";
}

.icon-Euros_POS:before {
  content: "";
}

.icon-Exclamation_mark_NEG:before {
  content: "";
}

.icon-Exclamation_mark_POS:before {
  content: "";
}

.icon-Exit_logout_NEG:before {
  content: "";
}

.icon-Exit_logout_POS:before {
  content: "";
}

.icon-External_link_NEG:before {
  content: "";
}

.icon-External_link_POS:before {
  content: "";
}

.icon-Eye_view_NEG:before {
  content: "";
}

.icon-Eye_view_POS:before {
  content: "";
}

.icon-Eyedropper_NEG:before {
  content: "";
}

.icon-Eyedropper_POS:before {
  content: "";
}

.icon-Factory_1_NEG:before {
  content: "";
}

.icon-Factory_1_POS:before {
  content: "";
}

.icon-Factory_2_NEG:before {
  content: "";
}

.icon-Factory_2_POS:before {
  content: "";
}

.icon-Fast_food_NEG:before {
  content: "";
}

.icon-Fast_food_POS:before {
  content: "";
}

.icon-Fast_forward_NEG:before {
  content: "";
}

.icon-Fast_forward_POS:before {
  content: "";
}

.icon-Faucet_water_NEG:before {
  content: "";
}

.icon-Faucet_water_POS:before {
  content: "";
}

.icon-Fax_Machine_NEG:before {
  content: "";
}

.icon-Fax_Machine_POS:before {
  content: "";
}

.icon-Feather_NEG:before {
  content: "";
}

.icon-Feather_POS:before {
  content: "";
}

.icon-Female_Symbol_NEG:before {
  content: "";
}

.icon-Female_Symbol_POS:before {
  content: "";
}

.icon-File_audio_1_NEG:before {
  content: "";
}

.icon-File_audio_1_POS:before {
  content: "";
}

.icon-File_code_NEG:before {
  content: "";
}

.icon-File_code_POS:before {
  content: "";
}

.icon-File_DOC_NEG:before {
  content: "";
}

.icon-File_DOC_POS:before {
  content: "";
}

.icon-File_edit_NEG:before {
  content: "";
}

.icon-File_edit_POS:before {
  content: "";
}

.icon-File_Movie_video_NEG:before {
  content: "";
}

.icon-File_Movie_video_POS:before {
  content: "";
}

.icon-File_new_add_NEG:before {
  content: "";
}

.icon-File_new_add_POS:before {
  content: "";
}

.icon-File_PDF_NEG:before {
  content: "";
}

.icon-File_PDF_POS:before {
  content: "";
}

.icon-File_picture_image_NEG:before {
  content: "";
}

.icon-File_picture_image_POS:before {
  content: "";
}

.icon-File_PPT_NEG:before {
  content: "";
}

.icon-File_PPT_POS:before {
  content: "";
}

.icon-File_XLS_NEG:before {
  content: "";
}

.icon-File_XLS_POS:before {
  content: "";
}

.icon-File_ZIP_NEG:before {
  content: "";
}

.icon-File_ZIP_POS:before {
  content: "";
}

.icon-Filter_NEG:before {
  content: "";
}

.icon-Filter_POS:before {
  content: "";
}

.icon-Finger_push_NEG:before {
  content: "";
}

.icon-Finger_push_POS:before {
  content: "";
}

.icon-Fir_tree_NEG:before {
  content: "";
}

.icon-Fir_tree_POS:before {
  content: "";
}

.icon-Fire_extinguisher_2_NEG:before {
  content: "";
}

.icon-Fire_extinguisher_2_POS:before {
  content: "";
}

.icon-Fire_NEG:before {
  content: "";
}

.icon-Fire_POS:before {
  content: "";
}

.icon-Fire_Stairs_NEG:before {
  content: "";
}

.icon-Fire_Stairs_POS:before {
  content: "";
}

.icon-Fire_truck_NEG:before {
  content: "";
}

.icon-Fire_truck_POS:before {
  content: "";
}

.icon-First_aid_kit_medicine_NEG:before {
  content: "";
}

.icon-First_aid_kit_medicine_POS:before {
  content: "";
}

.icon-First_aid_medicine_NEG:before {
  content: "";
}

.icon-First_aid_medicine_POS:before {
  content: "";
}

.icon-Fish_hook_NEG:before {
  content: "";
}

.icon-Fish_hook_POS:before {
  content: "";
}

.icon-Flag_NEG:before {
  content: "";
}

.icon-Flag_POS:before {
  content: "";
}

.icon-Flip_chart_easel_NEG:before {
  content: "";
}

.icon-Flip_chart_easel_POS:before {
  content: "";
}

.icon-Flower_NEG:before {
  content: "";
}

.icon-Flower_POS:before {
  content: "";
}

.icon-Folder_1_NEG:before {
  content: "";
}

.icon-Folder_1_POS:before {
  content: "";
}

.icon-Folder_Incoming_NEG:before {
  content: "";
}

.icon-Folder_Incoming_POS:before {
  content: "";
}

.icon-Folder_outgoing_NEG:before {
  content: "";
}

.icon-Folder_outgoing_POS:before {
  content: "";
}

.icon-Folders_NEG:before {
  content: "";
}

.icon-Folders_POS:before {
  content: "";
}

.icon-Football_NEG:before {
  content: "";
}

.icon-Football_POS:before {
  content: "";
}

.icon-Fork_lift_truck_NEG:before {
  content: "";
}

.icon-Fork_lift_truck_POS:before {
  content: "";
}

.icon-Forward_NEG:before {
  content: "";
}

.icon-Forward_POS:before {
  content: "";
}

.icon-Frame_2_NEG:before {
  content: "";
}

.icon-Frame_2_POS:before {
  content: "";
}

.icon-Frame_NEG:before {
  content: "";
}

.icon-Frame_POS:before {
  content: "";
}

.icon-Gamepad_NEG:before {
  content: "";
}

.icon-Gamepad_POS:before {
  content: "";
}

.icon-Gavel_NEG:before {
  content: "";
}

.icon-Gavel_POS:before {
  content: "";
}

.icon-Gesture_double_tap_NEG:before {
  content: "";
}

.icon-Gesture_double_tap_POS:before {
  content: "";
}

.icon-Gesture_hold_NEG:before {
  content: "";
}

.icon-Gesture_hold_POS:before {
  content: "";
}

.icon-Gesture_move_NEG:before {
  content: "";
}

.icon-Gesture_move_POS:before {
  content: "";
}

.icon-Gesture_pinch_NEG:before {
  content: "";
}

.icon-Gesture_pinch_POS:before {
  content: "";
}

.icon-Gesture_rotate_NEG:before {
  content: "";
}

.icon-Gesture_rotate_POS:before {
  content: "";
}

.icon-Gesture_swipe_left_NEG:before {
  content: "";
}

.icon-Gesture_swipe_left_POS:before {
  content: "";
}

.icon-Gesture_swipe_NEG:before {
  content: "";
}

.icon-Gesture_swipe_POS:before {
  content: "";
}

.icon-Gesture_swipe_right_NEG:before {
  content: "";
}

.icon-Gesture_swipe_right_POS:before {
  content: "";
}

.icon-Gesture_tap_NEG:before {
  content: "";
}

.icon-Gesture_tap_POS:before {
  content: "";
}

.icon-Gesture_touch_NEG:before {
  content: "";
}

.icon-Gesture_touch_POS:before {
  content: "";
}

.icon-Gesture_two_finger_move_NEG:before {
  content: "";
}

.icon-Gesture_two_finger_move_POS:before {
  content: "";
}

.icon-Gesture_two_finger_swipe_left_NEG:before {
  content: "";
}

.icon-Gesture_two_finger_swipe_left_POS:before {
  content: "";
}

.icon-Gesture_two_finger_swipe_right_NEG:before {
  content: "";
}

.icon-Gesture_two_finger_swipe_right_POS:before {
  content: "";
}

.icon-Gesture_two_finger_tap_NEG:before {
  content: "";
}

.icon-Gesture_two_finger_tap_POS:before {
  content: "";
}

.icon-Gesture_zoom_in_NEG:before {
  content: "";
}

.icon-Gesture_zoom_in_POS:before {
  content: "";
}

.icon-Gesture_zoom_out_NEG:before {
  content: "";
}

.icon-Gesture_zoom_out_POS:before {
  content: "";
}

.icon-Gift_NEG:before {
  content: "";
}

.icon-Gift_POS:before {
  content: "";
}

.icon-glasses_NEG:before {
  content: "";
}

.icon-glasses_POS:before {
  content: "";
}

.icon-Globe_NEG:before {
  content: "";
}

.icon-Globe_POS:before {
  content: "";
}

.icon-Globe_with_stand_NEG:before {
  content: "";
}

.icon-Globe_with_stand_POS:before {
  content: "";
}

.icon-Government_NEG:before {
  content: "";
}

.icon-Government_POS:before {
  content: "";
}

.icon-Grid_NEG:before {
  content: "";
}

.icon-Grid_POS:before {
  content: "";
}

.icon-Gym_sport_NEG:before {
  content: "";
}

.icon-Gym_sport_POS:before {
  content: "";
}

.icon-Hail_NEG:before {
  content: "";
}

.icon-Hail_POS:before {
  content: "";
}

.icon-Hammer_NEG:before {
  content: "";
}

.icon-Hammer_POS:before {
  content: "";
}

.icon-Hand_grab_NEG:before {
  content: "";
}

.icon-Hand_grab_POS:before {
  content: "";
}

.icon-Hand_pointing_down_NEG:before {
  content: "";
}

.icon-Hand_pointing_down_POS:before {
  content: "";
}

.icon-Hand_pointing_left_NEG:before {
  content: "";
}

.icon-Hand_pointing_left_POS:before {
  content: "";
}

.icon-Hand_pointing_right_NEG:before {
  content: "";
}

.icon-Hand_pointing_right_POS:before {
  content: "";
}

.icon-Hand_pointing_up_NEG:before {
  content: "";
}

.icon-Hand_pointing_up_POS:before {
  content: "";
}

.icon-Hand_shake_NEG:before {
  content: "";
}

.icon-Hand_shake_POS:before {
  content: "";
}

.icon-Hashtag_NEG:before {
  content: "";
}

.icon-Hashtag_POS:before {
  content: "";
}

.icon-HDD_NEG:before {
  content: "";
}

.icon-HDD_POS:before {
  content: "";
}

.icon-Headphones_NEG:before {
  content: "";
}

.icon-Headphones_POS:before {
  content: "";
}

.icon-Headset_NEG:before {
  content: "";
}

.icon-Headset_POS:before {
  content: "";
}

.icon-Heart_NEG:before {
  content: "";
}

.icon-Heart_POS:before {
  content: "";
}

.icon-Heartrate_NEG:before {
  content: "";
}

.icon-Heartrate_POS:before {
  content: "";
}

.icon-Heavy_rain_NEG:before {
  content: "";
}

.icon-Heavy_rain_POS:before {
  content: "";
}

.icon-Helmet_mining_NEG:before {
  content: "";
}

.icon-Helmet_mining_POS:before {
  content: "";
}

.icon-Helmet_NEG:before {
  content: "";
}

.icon-Helmet_POS:before {
  content: "";
}

.icon-HGV_car_truck_NEG:before {
  content: "";
}

.icon-HGV_car_truck_POS:before {
  content: "";
}

.icon-Hifi_speaker_audio_NEG:before {
  content: "";
}

.icon-Hifi_speaker_audio_POS:before {
  content: "";
}

.icon-History_globe_NEG:before {
  content: "";
}

.icon-History_globe_POS:before {
  content: "";
}

.icon-Home_house_NEG:before {
  content: "";
}

.icon-Home_house_POS:before {
  content: "";
}

.icon-Hospital_NEG:before {
  content: "";
}

.icon-Hospital_POS:before {
  content: "";
}

.icon-House_home_NEG:before {
  content: "";
}

.icon-House_home_POS:before {
  content: "";
}

.icon-Houses_home_NEG:before {
  content: "";
}

.icon-Houses_home_POS:before {
  content: "";
}

.icon-ID_card_profile_NEG:before {
  content: "";
}

.icon-ID_card_profile_POS:before {
  content: "";
}

.icon-Inbox_NEG:before {
  content: "";
}

.icon-Inbox_POS:before {
  content: "";
}

.icon-Increase_1_arrow_NEG:before {
  content: "";
}

.icon-Increase_1_arrow_POS:before {
  content: "";
}

.icon-Increase_2_arrow_NEG:before {
  content: "";
}

.icon-Increase_2_arrow_POS:before {
  content: "";
}

.icon-Increase_3_arrow_NEG:before {
  content: "";
}

.icon-Increase_3_arrow_POS:before {
  content: "";
}

.icon-Increase_4_arrow_NEG:before {
  content: "";
}

.icon-Increase_4_arrow_POS:before {
  content: "";
}

.icon-Increase_5_arrow_NEG:before {
  content: "";
}

.icon-Increase_5_arrow_POS:before {
  content: "";
}

.icon-Infinity_loop_NEG:before {
  content: "";
}

.icon-Infinity_loop_POS:before {
  content: "";
}

.icon-Information_NEG:before {
  content: "";
}

.icon-Information_POS:before {
  content: "";
}

.icon-Kennel_NEG:before {
  content: "";
}

.icon-Kennel_POS:before {
  content: "";
}

.icon-Key_1_NEG:before {
  content: "";
}

.icon-Key_1_POS:before {
  content: "";
}

.icon-Key_2_NEG:before {
  content: "";
}

.icon-Key_2_POS:before {
  content: "";
}

.icon-Keyboard_NEG:before {
  content: "";
}

.icon-Keyboard_POS:before {
  content: "";
}

.icon-Ladder_NEG:before {
  content: "";
}

.icon-Ladder_POS:before {
  content: "";
}

.icon-Laptop_monitor_display_NEG:before {
  content: "";
}

.icon-Laptop_monitor_display_POS:before {
  content: "";
}

.icon-Leaf_NEG:before {
  content: "";
}

.icon-Leaf_POS:before {
  content: "";
}

.icon-Life_buoy_NEG:before {
  content: "";
}

.icon-Life_buoy_POS:before {
  content: "";
}

.icon-Light_rain_NEG:before {
  content: "";
}

.icon-Light_rain_POS:before {
  content: "";
}

.icon-Lightbulb_energy_lamp_NEG:before {
  content: "";
}

.icon-Lightbulb_energy_lamp_POS:before {
  content: "";
}

.icon-Lightbulb_lamp_NEG:before {
  content: "";
}

.icon-Lightbulb_lamp_POS:before {
  content: "";
}

.icon-Lighthouse_NEG:before {
  content: "";
}

.icon-Lighthouse_POS:before {
  content: "";
}

.icon-Lightning_bolt_NEG:before {
  content: "";
}

.icon-Lightning_bolt_POS:before {
  content: "";
}

.icon-Lightning_NEG:before {
  content: "";
}

.icon-Lightning_POS:before {
  content: "";
}

.icon-Like_NEG:before {
  content: "";
}

.icon-Like_POS:before {
  content: "";
}

.icon-Line_graph_chart_NEG:before {
  content: "";
}

.icon-Line_graph_chart_POS:before {
  content: "";
}

.icon-Link_NEG:before {
  content: "";
}

.icon-Link_POS:before {
  content: "";
}

.icon-List_2_NEG:before {
  content: "";
}

.icon-List_2_POS:before {
  content: "";
}

.icon-List_NEG:before {
  content: "";
}

.icon-List_POS:before {
  content: "";
}

.icon-Lock_NEG:before {
  content: "";
}

.icon-Lock_POS:before {
  content: "";
}

.icon-Loudhailer_speaker_NEG:before {
  content: "";
}

.icon-Loudhailer_speaker_POS:before {
  content: "";
}

.icon-Magic_NEG:before {
  content: "";
}

.icon-Magic_POS:before {
  content: "";
}

.icon-Magnet_1_NEG:before {
  content: "";
}

.icon-Magnet_1_POS:before {
  content: "";
}

.icon-Magnet_2_NEG:before {
  content: "";
}

.icon-Magnet_2_POS:before {
  content: "";
}

.icon-Mail_closed_NEG:before {
  content: "";
}

.icon-Mail_closed_POS:before {
  content: "";
}

.icon-Mail_empty_NEG:before {
  content: "";
}

.icon-Mail_empty_POS:before {
  content: "";
}

.icon-Mail_full_NEG:before {
  content: "";
}

.icon-Mail_full_POS:before {
  content: "";
}

.icon-Mail_in_NEG:before {
  content: "";
}

.icon-Mail_in_POS:before {
  content: "";
}

.icon-Mail_out_NEG:before {
  content: "";
}

.icon-Mail_out_POS:before {
  content: "";
}

.icon-Male_female_symbol_NEG:before {
  content: "";
}

.icon-Male_female_symbol_POS:before {
  content: "";
}

.icon-Male_Symbol_NEG:before {
  content: "";
}

.icon-Male_Symbol_POS:before {
  content: "";
}

.icon-Man_NEG:before {
  content: "";
}

.icon-Man_POS:before {
  content: "";
}

.icon-Man_silhouette_user_NEG:before {
  content: "";
}

.icon-Man_silhouette_user_POS:before {
  content: "";
}

.icon-Man_woman_NEG:before {
  content: "";
}

.icon-Man_woman_POS:before {
  content: "";
}

.icon-Map_NEG:before {
  content: "";
}

.icon-Map_POS:before {
  content: "";
}

.icon-Martini_glass_NEG:before {
  content: "";
}

.icon-Martini_glass_POS:before {
  content: "";
}

.icon-Mathematics_calculator_NEG:before {
  content: "";
}

.icon-Mathematics_calculator_POS:before {
  content: "";
}

.icon-Maze_labyrinth_NEG:before {
  content: "";
}

.icon-Maze_labyrinth_POS:before {
  content: "";
}

.icon-Medical_star_of_life_NEG:before {
  content: "";
}

.icon-Medical_star_of_life_POS:before {
  content: "";
}

.icon-Meeting_conference_NEG:before {
  content: "";
}

.icon-Meeting_conference_POS:before {
  content: "";
}

.icon-Merge_NEG:before {
  content: "";
}

.icon-Merge_POS:before {
  content: "";
}

.icon-Microchip_NEG:before {
  content: "";
}

.icon-Microchip_POS:before {
  content: "";
}

.icon-Microphone_1_NEG:before {
  content: "";
}

.icon-Microphone_1_POS:before {
  content: "";
}

.icon-Microphone_2_NEG:before {
  content: "";
}

.icon-Microphone_2_POS:before {
  content: "";
}

.icon-Microphone_3_NEG:before {
  content: "";
}

.icon-Microphone_3_POS:before {
  content: "";
}

.icon-Microscope_NEG:before {
  content: "";
}

.icon-Microscope_POS:before {
  content: "";
}

.icon-Minus_NEG:before {
  content: "";
}

.icon-Minus_POS:before {
  content: "";
}

.icon-Mobile_pay_NEG:before {
  content: "";
}

.icon-Mobile_pay_POS:before {
  content: "";
}

.icon-Mobile_phone_NEG:before {
  content: "";
}

.icon-Mobile_phone_POS:before {
  content: "";
}

.icon-Money_bag_NEG:before {
  content: "";
}

.icon-Money_bag_POS:before {
  content: "";
}

.icon-Moon_NEG:before {
  content: "";
}

.icon-Moon_POS:before {
  content: "";
}

.icon-Mortarboard_education_NEG:before {
  content: "";
}

.icon-Mortarboard_education_POS:before {
  content: "";
}

.icon-Mouse_NEG:before {
  content: "";
}

.icon-Mouse_POS:before {
  content: "";
}

.icon-Movie_clip_video_NEG:before {
  content: "";
}

.icon-Movie_clip_video_POS:before {
  content: "";
}

.icon-Movie_reel_video_NEG:before {
  content: "";
}

.icon-Movie_reel_video_POS:before {
  content: "";
}

.icon-Music_NEG:before {
  content: "";
}

.icon-Music_POS:before {
  content: "";
}

.icon-Navigation_1_pin_location_NEG:before {
  content: "";
}

.icon-Navigation_1_pin_location_POS:before {
  content: "";
}

.icon-Navigation_2_NEG:before {
  content: "";
}

.icon-Navigation_2_POS:before {
  content: "";
}

.icon-Newspaper_NEG:before {
  content: "";
}

.icon-Newspaper_POS:before {
  content: "";
}

.icon-No_entry_NEG:before {
  content: "";
}

.icon-No_entry_POS:before {
  content: "";
}

.icon-No_smoking_NEG:before {
  content: "";
}

.icon-No_smoking_POS:before {
  content: "";
}

.icon-Non_visable_disable_eye_NEG:before {
  content: "";
}

.icon-Non_visable_disable_eye_POS:before {
  content: "";
}

.icon-Object_group_NEG:before {
  content: "";
}

.icon-Object_group_POS:before {
  content: "";
}

.icon-Object_ungroup_NEG:before {
  content: "";
}

.icon-Object_ungroup_POS:before {
  content: "";
}

.icon-Office_1_NEG:before {
  content: "";
}

.icon-Office_1_POS:before {
  content: "";
}

.icon-Office_2_NEG:before {
  content: "";
}

.icon-Office_2_POS:before {
  content: "";
}

.icon-Office_3_NEG:before {
  content: "";
}

.icon-Office_3_POS:before {
  content: "";
}

.icon-Office_4_NEG:before {
  content: "";
}

.icon-Office_4_POS:before {
  content: "";
}

.icon-Office_5_NEG:before {
  content: "";
}

.icon-Office_5_POS:before {
  content: "";
}

.icon-Office_locator_globe_NEG:before {
  content: "";
}

.icon-Office_locator_globe_POS:before {
  content: "";
}

.icon-Oil_barrel_NEG:before {
  content: "";
}

.icon-Oil_barrel_POS:before {
  content: "";
}

.icon-Oil_derrick_NEG:before {
  content: "";
}

.icon-Oil_derrick_POS:before {
  content: "";
}

.icon-Open_box_NEG:before {
  content: "";
}

.icon-Open_box_POS:before {
  content: "";
}

.icon-Open_folder_NEG:before {
  content: "";
}

.icon-Open_folder_POS:before {
  content: "";
}

.icon-Options_blocks_NEG:before {
  content: "";
}

.icon-Options_blocks_POS:before {
  content: "";
}

.icon-Org_chart_NEG:before {
  content: "";
}

.icon-Org_chart_POS:before {
  content: "";
}

.icon-Overcast_NEG:before {
  content: "";
}

.icon-Overcast_POS:before {
  content: "";
}

.icon-Owl_NEG:before {
  content: "";
}

.icon-Owl_POS:before {
  content: "";
}

.icon-Pacifier_NEG:before {
  content: "";
}

.icon-Pacifier_POS:before {
  content: "";
}

.icon-Package_box_NEG:before {
  content: "";
}

.icon-Package_box_POS:before {
  content: "";
}

.icon-Paint_palette_NEG:before {
  content: "";
}

.icon-Paint_palette_POS:before {
  content: "";
}

.icon-Paintbrush_NEG:before {
  content: "";
}

.icon-Paintbrush_POS:before {
  content: "";
}

.icon-Paper_plane_NEG:before {
  content: "";
}

.icon-Paper_plane_POS:before {
  content: "";
}

.icon-Paperclip_NEG:before {
  content: "";
}

.icon-Paperclip_POS:before {
  content: "";
}

.icon-Paragraph_NEG:before {
  content: "";
}

.icon-Paragraph_POS:before {
  content: "";
}

.icon-Paste_clipboard_NEG:before {
  content: "";
}

.icon-Paste_clipboard_POS:before {
  content: "";
}

.icon-Pause_NEG:before {
  content: "";
}

.icon-Pause_POS:before {
  content: "";
}

.icon-Paw_print_NEG:before {
  content: "";
}

.icon-Paw_print_POS:before {
  content: "";
}

.icon-Pen_NEG:before {
  content: "";
}

.icon-Pen_POS:before {
  content: "";
}

.icon-Pencil_edit_NEG:before {
  content: "";
}

.icon-Pencil_edit_POS:before {
  content: "";
}

.icon-Percent_NEG:before {
  content: "";
}

.icon-Percent_POS:before {
  content: "";
}

.icon-Pestle_and_mortar_NEG:before {
  content: "";
}

.icon-Pestle_and_mortar_POS:before {
  content: "";
}

.icon-Petrol_can_NEG:before {
  content: "";
}

.icon-Petrol_can_POS:before {
  content: "";
}

.icon-Petrol_pump_NEG:before {
  content: "";
}

.icon-Petrol_pump_POS .path1:before {
  content: "";
  color: black;
}

.icon-Petrol_pump_POS .path2:before {
  content: "";
  margin-left: -1em;
  color: #050606;
}

.icon-Phone_NEG:before {
  content: "";
}

.icon-Phone_POS:before {
  content: "";
}

.icon-Picture_image_NEG:before {
  content: "";
}

.icon-Picture_image_POS:before {
  content: "";
}

.icon-Pie_chart_1_NEG:before {
  content: "";
}

.icon-Pie_chart_1_POS:before {
  content: "";
}

.icon-Pie_chart_2_NEG:before {
  content: "";
}

.icon-Pie_chart_2_POS:before {
  content: "";
}

.icon-Piggy_bank_NEG:before {
  content: "";
}

.icon-Piggy_bank_POS:before {
  content: "";
}

.icon-Pill_bottle_NEG:before {
  content: "";
}

.icon-Pill_bottle_POS:before {
  content: "";
}

.icon-Pill_NEG:before {
  content: "";
}

.icon-Pill_POS:before {
  content: "";
}

.icon-Pin_map_NEG:before {
  content: "";
}

.icon-Pin_map_POS:before {
  content: "";
}

.icon-Plane_1_NEG:before {
  content: "";
}

.icon-Plane_1_POS:before {
  content: "";
}

.icon-Planet_NEG:before {
  content: "";
}

.icon-Planet_POS:before {
  content: "";
}

.icon-Play_NEG:before {
  content: "";
}

.icon-Play_POS:before {
  content: "";
}

.icon-Plot_graph_chart_NEG:before {
  content: "";
}

.icon-Plot_graph_chart_POS:before {
  content: "";
}

.icon-Plug_US_NEG:before {
  content: "";
}

.icon-Plug_US_POS:before {
  content: "";
}

.icon-Plus_NEG:before {
  content: "";
}

.icon-Plus_POS:before {
  content: "";
}

.icon-Podcast_NEG:before {
  content: "";
}

.icon-Podcast_POS:before {
  content: "";
}

.icon-Pound_NEG:before {
  content: "";
}

.icon-Pound_POS:before {
  content: "";
}

.icon-Power_exit_NEG:before {
  content: "";
}

.icon-Power_exit_POS:before {
  content: "";
}

.icon-Power_socket_UK_NEG:before {
  content: "";
}

.icon-Power_socket_UK_POS:before {
  content: "";
}

.icon-Power_socket_US_NEG:before {
  content: "";
}

.icon-Power_socket_US_POS:before {
  content: "";
}

.icon-Presentation_comment_NEG:before {
  content: "";
}

.icon-Presentation_comment_POS:before {
  content: "";
}

.icon-Printer_NEG:before {
  content: "";
}

.icon-Printer_POS:before {
  content: "";
}

.icon-Profile_2_user_NEG:before {
  content: "";
}

.icon-Profile_2_POS:before {
  content: "";
}

.icon-Profile_user_NEG:before {
  content: "";
}

.icon-Profile_user_POS:before {
  content: "";
}

.icon-Propeller_NEG:before {
  content: "";
}

.icon-Propeller_POS:before {
  content: "";
}

.icon-Public_Building_NEG:before {
  content: "";
}

.icon-Public_Building_POS:before {
  content: "";
}

.icon-Push_chair_NEG:before {
  content: "";
}

.icon-Push_chair_POS:before {
  content: "";
}

.icon-Push_pin_NEG:before {
  content: "";
}

.icon-Push_pin_POS:before {
  content: "";
}

.icon-Puzzle_NEG:before {
  content: "";
}

.icon-Puzzle_POS:before {
  content: "";
}

.icon-QR_code_NEG:before {
  content: "";
}

.icon-QR_code_POS:before {
  content: "";
}

.icon-Question_mark_NEG:before {
  content: "";
}

.icon-Question_mark_POS:before {
  content: "";
}

.icon-Quote_marks_2_NEG:before {
  content: "";
}

.icon-Quote_marks_2_POS:before {
  content: "";
}

.icon-Quote_marks_NEG:before {
  content: "";
}

.icon-Quote_marks_POS:before {
  content: "";
}

.icon-Rabbit_NEG:before {
  content: "";
}

.icon-Rabbit_POS:before {
  content: "";
}

.icon-Radar_NEG:before {
  content: "";
}

.icon-Radar_POS:before {
  content: "";
}

.icon-Radio_NEG:before {
  content: "";
}

.icon-Radio_POS:before {
  content: "";
}

.icon-Rail_bridge_NEG:before {
  content: "";
}

.icon-Rail_bridge_POS:before {
  content: "";
}

.icon-Rainbow_NEG:before {
  content: "";
}

.icon-Rainbow_POS:before {
  content: "";
}

.icon-Realtor_sign_NEG:before {
  content: "";
}

.icon-Realtor_sign_POS:before {
  content: "";
}

.icon-Record_NEG:before {
  content: "";
}

.icon-Record_POS:before {
  content: "";
}

.icon-Recycle_NEG:before {
  content: "";
}

.icon-Recycle_POS:before {
  content: "";
}

.icon-Refresh_arrow_NEG:before {
  content: "";
}

.icon-Refresh_arrow_POS:before {
  content: "";
}

.icon-Reply_all_NEG:before {
  content: "";
}

.icon-Reply_all_POS:before {
  content: "";
}

.icon-Reply_NEG:before {
  content: "";
}

.icon-Reply_POS:before {
  content: "";
}

.icon-Return_arrow_NEG:before {
  content: "";
}

.icon-Return_arrow_POS:before {
  content: "";
}

.icon-Reverse_arrow_NEG:before {
  content: "";
}

.icon-Reverse_arrow_POS:before {
  content: "";
}

.icon-Rewind_NEG:before {
  content: "";
}

.icon-Rewind_POS:before {
  content: "";
}

.icon-River_bridge_NEG:before {
  content: "";
}

.icon-River_bridge_POS:before {
  content: "";
}

.icon-Road_bridge_NEG:before {
  content: "";
}

.icon-Road_bridge_POS:before {
  content: "";
}

.icon-Rocket_NEG:before {
  content: "";
}

.icon-Rocket_POS:before {
  content: "";
}

.icon-Rosette_NEG:before {
  content: "";
}

.icon-Rosette_POS:before {
  content: "";
}

.icon-Rotate_arrow_NEG:before {
  content: "";
}

.icon-Rotate_arrow_POS:before {
  content: "";
}

.icon-Route_1_NEG:before {
  content: "";
}

.icon-Route_1_POS:before {
  content: "";
}

.icon-Route_2_NEG:before {
  content: "";
}

.icon-Route_2_POS:before {
  content: "";
}

.icon-Route_3_NEG:before {
  content: "";
}

.icon-Route_3_POS:before {
  content: "";
}

.icon-RSS_NEG:before {
  content: "";
}

.icon-RSS_POS:before {
  content: "";
}

.icon-Ruler_NEG:before {
  content: "";
}

.icon-Ruler_POS:before {
  content: "";
}

.icon-Safe_NEG:before {
  content: "";
}

.icon-Safe_POS:before {
  content: "";
}

.icon-Sail_boat_NEG:before {
  content: "";
}

.icon-Sail_boat_POS:before {
  content: "";
}

.icon-Satchel_suitcase_NEG:before {
  content: "";
}

.icon-Satchel_suitcase_POS:before {
  content: "";
}

.icon-Satellite_dish_NEG:before {
  content: "";
}

.icon-Satellite_dish_POS:before {
  content: "";
}

.icon-Satellite_NEG:before {
  content: "";
}

.icon-Satellite_POS:before {
  content: "";
}

.icon-Save_NEG:before {
  content: "";
}

.icon-Save_POS:before {
  content: "";
}

.icon-Scales_NEG:before {
  content: "";
}

.icon-Scales_POS:before {
  content: "";
}

.icon-Scissors_NEG:before {
  content: "";
}

.icon-Scissors_POS:before {
  content: "";
}

.icon-Screwdriver_NEG:before {
  content: "";
}

.icon-Screwdriver_POS:before {
  content: "";
}

.icon-Search_jobs_NEG:before {
  content: "";
}

.icon-Search_jobs_POS:before {
  content: "";
}

.icon-Search_NEG:before {
  content: "";
}

.icon-Search_POS:before {
  content: "";
}

.icon-Self_drive_car_NEG:before {
  content: "";
}

.icon-Self_drive_car_POS:before {
  content: "";
}

.icon-Send_paper_plane_NEG:before {
  content: "";
}

.icon-Send_paper_plane_POS:before {
  content: "";
}

.icon-Share_NEG:before {
  content: "";
}

.icon-Share_POS:before {
  content: "";
}

.icon-Shared_Office_NEG:before {
  content: "";
}

.icon-Shared_Office_POS:before {
  content: "";
}

.icon-Shield_NEG:before {
  content: "";
}

.icon-Shield_POS:before {
  content: "";
}

.icon-Ship_NEG:before {
  content: "";
}

.icon-Ship_POS:before {
  content: "";
}

.icon-Ship_wheel_NEG:before {
  content: "";
}

.icon-Ship_wheel_POS:before {
  content: "";
}

.icon-Shoe_prints_NEG:before {
  content: "";
}

.icon-Shoe_prints_POS:before {
  content: "";
}

.icon-Shoot_leaf_NEG:before {
  content: "";
}

.icon-Shoot_leaf_POS:before {
  content: "";
}

.icon-Shopping_bag_NEG:before {
  content: "";
}

.icon-Shopping_bag_POS:before {
  content: "";
}

.icon-Shopping_basket_NEG:before {
  content: "";
}

.icon-Shopping_basket_POS:before {
  content: "";
}

.icon-Shower_NEG:before {
  content: "";
}

.icon-Shower_POS:before {
  content: "";
}

.icon-Shuffle_arrow_NEG:before {
  content: "";
}

.icon-Shuffle_arrow_POS:before {
  content: "";
}

.icon-Simple_arrow_decrease_NEG:before {
  content: "";
}

.icon-Simple_arrow_decrease_POS:before {
  content: "";
}

.icon-Simple_arrow_down_NEG:before {
  content: "";
}

.icon-Simple_arrow_down_POS:before {
  content: "";
}

.icon-Simple_arrow_increase_NEG:before {
  content: "";
}

.icon-Simple_arrow_increase_POS:before {
  content: "";
}

.icon-Simple_arrow_left_NEG:before {
  content: "";
}

.icon-Simple_arrow_left_POS:before {
  content: "";
}

.icon-Simple_arrow_right_NEG:before {
  content: "";
}

.icon-Simple_arrow_right_POS:before {
  content: "";
}

.icon-Simple_arrow_side_NEG:before {
  content: "";
}

.icon-Simple_arrow_side_POS:before {
  content: "";
}

.icon-Simple_arrow_up_down_NEG:before {
  content: "";
}

.icon-Simple_arrow_up_down_POS:before {
  content: "";
}

.icon-Simple_arrow_up_NEG:before {
  content: "";
}

.icon-Simple_arrow_up_POS:before {
  content: "";
}

.icon-Single_office_NEG:before {
  content: "";
}

.icon-Single_office_POS:before {
  content: "";
}

.icon-Skip_backwards_NEG:before {
  content: "";
}

.icon-Skip_backwards_POS:before {
  content: "";
}

.icon-Skip_forward_NEG:before {
  content: "";
}

.icon-Skip_forward_POS:before {
  content: "";
}

.icon-Skip_Forwards_NEG:before {
  content: "";
}

.icon-Skip_Forwards_POS:before {
  content: "";
}

.icon-Skip_rewind__NEG:before {
  content: "";
}

.icon-Skip_rewind__POS:before {
  content: "";
}

.icon-Skull_n_Bones_NEG:before {
  content: "";
}

.icon-Skull_n_Bones_POS:before {
  content: "";
}

.icon-Slider_settings_NEG:before {
  content: "";
}

.icon-Slider_settings_POS:before {
  content: "";
}

.icon-Smart_watch_NEG:before {
  content: "";
}

.icon-Smart_watch_POS:before {
  content: "";
}

.icon-Snow_NEG:before {
  content: "";
}

.icon-Snow_POS:before {
  content: "";
}

.icon-Snowflake_NEG:before {
  content: "";
}

.icon-Snowflake_POS:before {
  content: "";
}

.icon-Sort_alpha_ascending_NEG:before {
  content: "";
}

.icon-Sort_alpha_ascending_POS:before {
  content: "";
}

.icon-Sort_alpha_descending_NEG:before {
  content: "";
}

.icon-Sort_alpha_descending_POS:before {
  content: "";
}

.icon-Sort_amount_ascending_NEG:before {
  content: "";
}

.icon-Sort_amount_ascending_POS:before {
  content: "";
}

.icon-Sort_amount_descending_NEG:before {
  content: "";
}

.icon-Sort_amount_descending_POS:before {
  content: "";
}

.icon-Sort_numeric_ascending_NEG:before {
  content: "";
}

.icon-Sort_numeric_ascending_POS:before {
  content: "";
}

.icon-Sort_numeric_descending_NEG:before {
  content: "";
}

.icon-Sort_numeric_descending_POS:before {
  content: "";
}

.icon-Spanner_NEG:before {
  content: "";
}

.icon-Spanner_POS:before {
  content: "";
}

.icon-Speaker_1_NEG:before {
  content: "";
}

.icon-Speaker_1_POS:before {
  content: "";
}

.icon-Speaker_2_NEG:before {
  content: "";
}

.icon-Speaker_2_POS:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_1_NEG:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_1_POS:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_2_NEG:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_2_POS:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_NEG:before {
  content: "";
}

.icon-Speech_commenting_chat_bubble_POS:before {
  content: "";
}

.icon-Speedometer_NEG:before {
  content: "";
}

.icon-Speedometer_POS:before {
  content: "";
}

.icon-Spin_arrow_NEG:before {
  content: "";
}

.icon-Spin_arrow_POS:before {
  content: "";
}

.icon-Split_arrow_NEG:before {
  content: "";
}

.icon-Split_arrow_POS:before {
  content: "";
}

.icon-Spoon_NEG:before {
  content: "";
}

.icon-Spoon_POS:before {
  content: "";
}

.icon-Stack_overflow_NEG:before {
  content: "";
}

.icon-Stack_overflow_POS:before {
  content: "";
}

.icon-Stairs_NEG:before {
  content: "";
}

.icon-Stairs_POS:before {
  content: "";
}

.icon-Star_NEG:before {
  content: "";
}

.icon-Star_POS:before {
  content: "";
}

.icon-Stethoscope_NEG:before {
  content: "";
}

.icon-Stethoscope_POS:before {
  content: "";
}

.icon-Stew_pot_NEG:before {
  content: "";
}

.icon-Stew_pot_POS:before {
  content: "";
}

.icon-Stop_NEG:before {
  content: "";
}

.icon-Stop_POS:before {
  content: "";
}

.icon-Stopwatch_timer_NEG:before {
  content: "";
}

.icon-Stopwatch_timer_POS:before {
  content: "";
}

.icon-Submit_RFP_NEG:before {
  content: "";
}

.icon-Submit_RFP_POS:before {
  content: "";
}

.icon-Suitcase_bag_NEG:before {
  content: "";
}

.icon-Suitcase_bag_POS:before {
  content: "";
}

.icon-Sun_n_Shade_NEG:before {
  content: "";
}

.icon-Sun_n_Shade_POS:before {
  content: "";
}

.icon-Sun_NEG:before {
  content: "";
}

.icon-Sun_POS:before {
  content: "";
}

.icon-Syringe_NEG:before {
  content: "";
}

.icon-Syringe_POS:before {
  content: "";
}

.icon-Tablet_NEG:before {
  content: "";
}

.icon-Tablet_POS:before {
  content: "";
}

.icon-Tag_NEG:before {
  content: "";
}

.icon-Tag_POS:before {
  content: "";
}

.icon-Target_NEG:before {
  content: "";
}

.icon-Target_POS:before {
  content: "";
}

.icon-Telescope_NEG:before {
  content: "";
}

.icon-Telescope_POS:before {
  content: "";
}

.icon-Television_display_device_monitor_NEG:before {
  content: "";
}

.icon-Television_display_device_monitor_POS:before {
  content: "";
}

.icon-Test_tube_NEG:before {
  content: "";
}

.icon-Test_tube_POS:before {
  content: "";
}

.icon-Test_tubes_NEG:before {
  content: "";
}

.icon-Test_tubes_POS:before {
  content: "";
}

.icon-Tick_list_NEG:before {
  content: "";
}

.icon-Tick_list_POS:before {
  content: "";
}

.icon-Tick_NEG:before {
  content: "";
}

.icon-Tick_POS:before {
  content: "";
}

.icon-Tie_NEG:before {
  content: "";
}

.icon-Tie_POS:before {
  content: "";
}

.icon-Toolbox_suitcase_NEG:before {
  content: "";
}

.icon-Toolbox_suitcase_POS:before {
  content: "";
}

.icon-Tortoise_NEG:before {
  content: "";
}

.icon-Tortoise_POS:before {
  content: "";
}

.icon-Tractor_NEG:before {
  content: "";
}

.icon-Tractor_POS:before {
  content: "";
}

.icon-Traffic_cone_NEG:before {
  content: "";
}

.icon-Traffic_cone_POS:before {
  content: "";
}

.icon-Traffic_signal_EU_NEG:before {
  content: "";
}

.icon-Traffic_signal_EU_POS:before {
  content: "";
}

.icon-Traffic_signal_NEG:before {
  content: "";
}

.icon-Traffic_signal_POS:before {
  content: "";
}

.icon-Train_NEG:before {
  content: "";
}

.icon-Train_POS:before {
  content: "";
}

.icon-Travel_coffee_cup_NEG:before {
  content: "";
}

.icon-Travel_coffee_cup_POS:before {
  content: "";
}

.icon-Tree_NEG:before {
  content: "";
}

.icon-Tree_POS:before {
  content: "";
}

.icon-Trolley_basket_NEG:before {
  content: "";
}

.icon-Trolley_basket_POS:before {
  content: "";
}

.icon-Trophy_NEG:before {
  content: "";
}

.icon-Trophy_POS:before {
  content: "";
}

.icon-Truck_car_NEG:before {
  content: "";
}

.icon-Truck_car_POS:before {
  content: "";
}

.icon-Tshirt_NEG:before {
  content: "";
}

.icon-Tshirt_POS:before {
  content: "";
}

.icon-Turbine_NEG:before {
  content: "";
}

.icon-Turbine_POS:before {
  content: "";
}

.icon-Type_cursor_NEG:before {
  content: "";
}

.icon-Type_cursor_POS:before {
  content: "";
}

.icon-Umbrella_NEG:before {
  content: "";
}

.icon-Umbrella_POS:before {
  content: "";
}

.icon-Unlink_NEG:before {
  content: "";
}

.icon-Unlink_POS:before {
  content: "";
}

.icon-Unlock_NEG:before {
  content: "";
}

.icon-Unlock_POS:before {
  content: "";
}

.icon-upload_NEG:before {
  content: "";
}

.icon-upload_POS:before {
  content: "";
}

.icon-upload_to_cloud_NEG:before {
  content: "";
}

.icon-upload_to_cloud_POS:before {
  content: "";
}

.icon-USB_NEG:before {
  content: "";
}

.icon-USB_POS:before {
  content: "";
}

.icon-User_2_profile_NEG:before {
  content: "";
}

.icon-User_2_profile_POS:before {
  content: "";
}

.icon-Users_NEG:before {
  content: "";
}

.icon-Users_POS:before {
  content: "";
}

.icon-Vending_machine_NEG:before {
  content: "";
}

.icon-Vending_machine_POS:before {
  content: "";
}

.icon-Video_NEG:before {
  content: "";
}

.icon-Video_POS:before {
  content: "";
}

.icon-Virtual_reality_NEG:before {
  content: "";
}

.icon-Virtual_reality_POS:before {
  content: "";
}

.icon-Volume_1_NEG:before {
  content: "";
}

.icon-Volume_1_POS:before {
  content: "";
}

.icon-Volume_2_NEG:before {
  content: "";
}

.icon-Volume_2_POS:before {
  content: "";
}

.icon-Volume_3_NEG:before {
  content: "";
}

.icon-Volume_3_POS:before {
  content: "";
}

.icon-Volume_4_NEG:before {
  content: "";
}

.icon-Volume_4_POS:before {
  content: "";
}

.icon-Walking_NEG:before {
  content: "";
}

.icon-Walking_POS:before {
  content: "";
}

.icon-Wheelchair_NEG:before {
  content: "";
}

.icon-Wheelchair_POS:before {
  content: "";
}

.icon-Wifi_NEG:before {
  content: "";
}

.icon-Wifi_POS:before {
  content: "";
}

.icon-Wine_glass_NEG:before {
  content: "";
}

.icon-Wine_glass_POS:before {
  content: "";
}

.icon-Woman_man_man_NEG:before {
  content: "";
}

.icon-Woman_man_man_POS:before {
  content: "";
}

.icon-Woman_man_NEG:before {
  content: "";
}

.icon-Woman_man_POS:before {
  content: "";
}

.icon-Woman_NEG:before {
  content: "";
}

.icon-Woman_POS:before {
  content: "";
}

.icon-Woman_silhouette_user_NEG:before {
  content: "";
}

.icon-Woman_silhouette_user_POS:before {
  content: "";
}

.icon-Woman_woman_man_NEG:before {
  content: "";
}

.icon-Woman_woman_man_POS:before {
  content: "";
}

.icon-Yen_NEG:before {
  content: "";
}

.icon-Yen_POS:before {
  content: "";
}

.icon-Zoom_in_NEG:before {
  content: "";
}

.icon-Zoom_in_POS:before {
  content: "";
}

.icon-Zoom_out_NEG:before {
  content: "";
}

.icon-Zoom_out_POS:before {
  content: "";
}